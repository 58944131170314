@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.orange-bg{
  background-color: orange/50;
}

.yellow-bg{
  background-color: yellow/50;
}

.blue-bg{
  background-color: blue/50;
}

:root{
  width: 100vw;
  min-height: 100vh;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-device-width: 600px){
  :root{
    height: auto;
    min-height: 100%;
  }
}

a{
  text-decoration: underline;
}

@layer components {
  .wrapper {
    @apply px-4 flex flex-col justify-center align-middle w-screen md:max-w-md;
  }
}